<template>
  <div class="review">
    <p class="review__body">“{{ body }}”</p>

    <div class="review__container">
      <img class="review__icon" src="@/assets/icons/user.svg" alt="" />
      <p class="review__author">{{ author }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    author: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.review {
  border-radius: 9px;
  background: #d4d1ce41;
  padding: 14px;
  margin: 38px 0;
  box-shadow: 0px 3px 6px #00000029;

  &__author {
    font-size: 12px;
  }

  &__container {
    margin-top: 14px;
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 16px;
    margin-right: 10px;
  }
}
</style>
