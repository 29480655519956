
/* eslint-disable @typescript-eslint/camelcase */

import { defineComponent, ref } from "vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import BaseHero from "@/components/base/BaseHero.vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";
import BaseReview from "@/components/base/BaseReview.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import client from "@/hooks/useContentful";

export default defineComponent({
  components: {
    BaseHero,
    BaseHeroImg,
    BaseAlinea,
    BaseReview,
    BaseSpinner
  },
  setup() {
    const reviews = ref<unknown>(null);

    client.getEntries({ content_type: "review" }).then((res) => {
      reviews.value = res.items;
    });

    return { reviews };
  }
});
