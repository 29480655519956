<template>
  <div class="reviews">
    <BaseHeroImg
      :imgSrc="require('@/assets/images/review-hero.jpg')"
      imgAlt="zee"
    />
    <BaseHero titleAlignment="left"> Recensies </BaseHero>

    <BaseAlinea v-if="reviews">
      <template #header>Recensies</template>

      <BaseReview
        v-for="r in reviews"
        :key="r.sys.id"
        :body="r.fields.review"
        :author="r.fields.author"
      />
    </BaseAlinea>
    <BaseSpinner v-else class="wrapper" />
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */

import { defineComponent, ref } from "vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import BaseHero from "@/components/base/BaseHero.vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";
import BaseReview from "@/components/base/BaseReview.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import client from "@/hooks/useContentful";

export default defineComponent({
  components: {
    BaseHero,
    BaseHeroImg,
    BaseAlinea,
    BaseReview,
    BaseSpinner
  },
  setup() {
    const reviews = ref<unknown>(null);

    client.getEntries({ content_type: "review" }).then((res) => {
      reviews.value = res.items;
    });

    return { reviews };
  }
});
</script>

<style lang="scss" scoped>
.reviews {
  position: relative;
  overflow-x: hidden;
  padding-bottom: 42px;
}
</style>
